.Home-sidebar {
  display: flex;
  flex-direction: column;
  height: calc(100% - 90px);
  width: 100%;
  min-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-between;
  background-color: #209973;
}

.Home-sidebar span {
  color: white;
}

.Home-sidebar li {
  margin: 1em 0;
}

.Home-sidebar li svg {
  width: 28px !important;
  height: 28px !important;
}

.Home-sidebar li .sidebarKodlyText svg {
  width: auto !important;
  height: auto !important;
}

.sidebarKodlyText {
  height: 26px;
}

.Home-sidebar .activeLink {
  background-color: #0f5156;
}

.HomeSidebar-kodlyIcon {
  cursor: pointer;
}

.HomeSidebar-logoutOpen {
  cursor: pointer;
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  bottom: 1.6em;
  left: 6.5em;
  position: relative;
}

.HomeSidebar-logout {
  cursor: pointer;
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  bottom: 1.5em;
  left: 0.8em;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background: #231f20;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00b189;
  border-radius: 10px;
}
