.sidebar {
  display: flex;
  flex-direction: column;
  height: calc(100% - 90px);
  width: 100%;
  min-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-between;
  background-color: #231f20 !important;
}

.sidebar span {
  color: white;
}

.sidebar li {
  margin: 1em 0;
}

.sidebar li svg {
  width: 28px !important;
  height: 28px !important;
}

.sidebar li .sidebarKodlyText svg {
  width: auto !important;
  height: auto !important;
}

.sidebarKodlyText {
  height: 26px;
}

.sidebar .activeLink {
  background-color: #c9ece4;
}

.sidebar .activeLink span {
  color: #353535;
}

.sidebar .activeLink svg > * {
  fill: #353535 !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background: #231f20;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00b189;
  border-radius: 10px;
}
