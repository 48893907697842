.button {
  border: 0;
  font-weight: 700;
  height: fit-content;
  border-radius: 24px;
  padding: 1em 2em 1em 2em;
  cursor: pointer;
}

.buttonPrimary {
  background-color: #00b189;
  color: #fff;
}

.buttonPrimary:hover {
  background-color: #fff;
  border: 2px solid #00b189;
  color: #00b189;
}

.buttonSecondary {
  background-color: #C9ECE4;
  color: #00b189;
}

.buttonSecondary:hover {
  background-color: #fff;
  border: 2px solid #C9ECE4;
  color: #00b189;
}

.buttonCancel {
  background-color: transparent;
  color: #00b189;
}

.buttonCancel:hover {
  background-color: #fff;
  border: 2px solid #00b189;
  color: #00b189;
}

.addButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  font-weight: 700;
  height: fit-content;
  border-radius: 24px;
  padding: 1em 2em 1em 2em;
  cursor: pointer;
}

.buttonDisabled {
  background-color: #ffffff !important;
  color: #dddddd !important;
  border: 2px solid #dddddd !important;
  border-radius: 24px !important;
  cursor: default;
}