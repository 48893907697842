.Home-kodlyIcon {
  display: flex;
  background-color: #209973;
  height: 90px;
  align-items: center;
  cursor: pointer;
}

.Page-title {
  color: #00B189;
  font-weight: 700;
  margin-right: 1em;
}
