.Page-kodlyIcon {
  display: flex;
  background-color: #231f20 !important;
  height: 90px;
  align-items: center;
}

.Page-gradient {
  border-bottom: 2px solid;
  border-image: linear-gradient(90deg, #00b189 0%, rgba(201, 236, 228, 0.91) 103.42%);
  border-image-slice: 1;
  vertical-align: bottom;
  position: fixed;
  z-index: 1;
  top: 90px;
  width: 100%;
}

.Page-title {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #00b189;
  font-weight: 700;
  flex-grow: 1;
  pointer-events: none;
}

.Page-kodlyIconImg {
  cursor: pointer;
}

.Page-menuSwitch_container {
  display: flex;
  width: 100%;
  align-items: center;
}

.Page-menuSwitch_element,
.Page-menuSwitch_activeElement {
  float: left;
  margin-left: 5%;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.Page-menuSwitch_activeElement {
  color: #29a476;
  font-weight: 700;
}

.Page-menuSwitch_element:hover {
  color: #29a476;
  font-weight: 700;
}

.Page-dashLine {
  border: 0;
  background-color: #29a476;
  height: 3px;
}
