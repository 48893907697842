
.ImageInputSquareAfterAdd-uploadContainer {
    border-radius: 32px;
    display: flex;
    justify-content: flex-end;
}

.ImageInputSquareAfterAdd-deleteButton {
    height: 26px;
    width: 26px;
    background: #000 !important;
}

.ImageInputSquareAfterAdd-deleteButton:hover {
    border: 2px solid #00b189;
    background: #000 !important;
}

.ImageInputSquareAfterAdd-deleteIcon {
    color: #00b189;
}